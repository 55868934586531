<template>
    <div class="pay">
        <div class="pay-head">
         <span class="iconfont icon-fanhuijiantou" @click="goHistotry()"></span>
        </div>
        <div class="pay-container">
            <div class="pay-topContent">
                <div class="pay-top">
                    <div class="pay-img">
                        <img src="../assets/images/shop/u95.png" />
                    </div>
                    <div class="pay-shopInfo">
                        <div class="pay-shopTitle">华为智能耳机</div>
                        <div class="pay-shopContent">
                            【有线充版】HUAWEI FreeBuds Pro 真无线耳机（陶瓷白）主动降噪 人声透传 快充长续航
                        </div>
                        <div class="shop-money">
                            <i class="iconfont icon-jinbi"></i>
                            <span class="money">10000</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pay-middle">
                <div class="Mode-of-delivery">
                    <div class="mode-title">运送方式</div>
                    <div class="fashi">
                        <span>快递</span>
                        <span>免运费</span>
                    </div>
                </div>
            </div>
            <div class="pay-middle">
                <div class="Mode-of-delivery">
                    <div class="mode-title">支付方式</div>
                    <div class="jifen">
                        <span>积分支付</span>
                        <!-- <span>免运费</span> -->
                    </div>
                </div>
            </div>
            <div class="pay-middle">
                <div class="Mode-of-delivery">
                    <div class="mode-title">订单金额</div>
                    <div class="fashi">
                        <span>共 1 件商品</span>
                        <span>合计:</span>
                        <span class="number">10000 积分</span>
                    </div>
                </div>
            </div>
            <div class="pay-btn">
                <div class="fukuang">
                    <span>实付款:</span>
                    <span>10000 积分</span>
                </div>
                <div class="fastPay">立即付款</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'pay',
    methods: {
        goHistotry() {
            this.$router.go(-1);
        },
    },
}
</script>

<style scoped>
@import '../assets/css/pay.less';
</style>